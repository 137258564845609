import { Component, forwardRef, Inject, Input } from "@angular/core";
import { DeviceFactoryProvider, ErrorSeverityLevel, FxpConstants, FxpMessageService, UserInfoService } from "@fxp/fxpservices";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { Components, LogEventConstants, SourceConstants, NotificationEvent, WBSResponseMessages, AccessibilityConstants } from "../../../../common/application.constants";
import { ConfigManagerService } from "../../../../common/services/configmanager.service";
import { DmDisplayDateOrDashPipe } from "../../../../common/services/filters/display-date-or-dash.pipe";
import { DMLoggerService } from "../../../../common/services/dmlogger.service";
import { DMNotificationService, NotificationModel } from "../../../../common/services/dmnotification.service";
import { EngagementDetailService } from "../../../../common/services/engagement-detail.service";
import { EnumUpdateLevel } from "../../../../common/services/contracts/wbs-engagement.contracts";
import { IChangedProperties } from "../../../../common/services/contracts/dmnotification.service.contract";
import { IEngagementDetailsV2 } from "../../../../common/services/contracts/wbs-details-v2.contracts";
import { IResponseMessage, IWbsEditEngagementDetailsV2, IDateValidationRequest, WbsLevel } from "../../../../common/services/contracts/wbs.service.contracts";
import { IScheduledDates, IResourceRequestResponse, IProjectRequest } from "../../../../common/services/contracts/staffing.service.contract";
import { MyPortfolioService } from "../../../../common/services/my-portfolio.service";
import { SharedFunctionsService } from "../../../../common/services/sharedfunctions.service";
import { StaffingService, IDemandSourceObject } from "../../../../common/services/staffing.service";
import { WBSService } from "../../../../common/services/wbs.service";
import moment from "moment";
import { DmNotificationService } from "../../../../common/services/dm-notification.service";
import { NotificationType } from "../../../../common/services/contracts/notification-bar.contracts";
import { v4 as uuid } from "uuid";
import { ProjectServiceFunctions } from "../../../../common/services/projectservice-functions.service";
import { DmError } from "../../../../common/error.constants";
import { IModal } from "../../../modals/dm-modal-v2/dm-modal-v2.component";
import { FormGroup, AbstractControl, Validators, FormBuilder } from "@angular/forms";
import { INotificationMessages } from "../../../../common/services/contracts/financial.service.contracts";
import { DmModalAbstract } from "../../../../common/abstraction/dm-modal.abstract";
import { IWbsAuditPayload, WbsAuditType } from "../../../../common/services/contracts/audit.contracts";
import { AuditService } from "../../../../common/services/audit.service";

const DATE_FORMAT = "YYYY-MM-DD";

@Component({
    templateUrl: "./edit-engagement-details.html",
    styleUrls: ["./edit-engagement-details.scss"]
})
export class EbsEditEngagementDetailsModalComponent extends DmModalAbstract {
    @Input() public selectedEngagement: IEngagementDetailsV2;
    public entityType: string;
    public isStartDateRequired: boolean = false;
    public isEndDateRequired: boolean = false;
    public showEndDateGreaterThanStartDateMessage: boolean = false;
    public isAfterChildProjectStartDate: boolean = false;
    public isBeforeChildProjectEndDate: boolean = false;
    public showActualsStartDateConflictMessage: boolean = false;
    public showActualsEndDateConflictMessage: boolean = false;
    public loadingText: string;
    public disableEBSStartDateEndDateUpdates: boolean = false;
    public viewResourceEnable: boolean = false;
    public isDisabledPubSecCode: boolean = false;
    public pubSecCountry: string;
    public showConflictingResources: boolean;
    public noOfConflictResources: number;
    public EnumUpdateLevel: typeof EnumUpdateLevel = EnumUpdateLevel;
    public minDate: Date;
    public maxStartDate: Date;
    public minStartDate: Date;
    public minEndDate: Date;
    public maxEndDate: Date;
    public isUpdateActive: boolean = false;
    public isResourceRequestsLoading: boolean;
    public accessibilityConstants = AccessibilityConstants;
    public editEngagementErrorMessages = DmError.EbsStructure.EditEbsStructure;
    public editEngagementDetailsForm: FormGroup;

    public get engagementName(): AbstractControl {
        return this.editEngagementDetailsForm.get("engagementName");
    }
    public get engagementDescription(): AbstractControl {
        return this.editEngagementDetailsForm.get("engagementDescription");
    }
    public get engagementStartDate(): AbstractControl {
        return this.editEngagementDetailsForm.get("engagementStartDate");
    }
    public get engagementEndDate(): AbstractControl {
        return this.editEngagementDetailsForm.get("engagementEndDate");
    }
    public get updateLevel(): AbstractControl {
        return this.editEngagementDetailsForm.get("updateLevel");
    }

    public modalContent: IModal;
    public showCascadeRadioButtons: boolean;
    public disableEngagementOnlyOption: boolean;
    public isAfterChildServiceStartDate: boolean = false;
    public initialStartDate: Date;
    public initialEndDate: Date;
    public isUpdatingEngagement: boolean;
    public isNamePristine: boolean = true;
    public isDescriptionPristine: boolean = true;
    public isStartDatePristine: boolean = true;
    public isEndDatePristine: boolean = true;

    private conflictResourceRequestStatus: string[];
    private serviceResponseMessages: IResponseMessage;
    private notificationMessage: INotificationMessages;
    private disableDateChangeForPubSecCodes: object;
    private readonly FXP_CONSTANTS = FxpConstants;
    private resourceRequests: IProjectRequest[] = [];
    private logEvent: string;
    private notificationEventName: string;
    private isEngagementMinDateValidationEnabled: boolean;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(ConfigManagerService) private configurationService: ConfigManagerService,
        @Inject(WBSService) private wbsService: WBSService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(DMNotificationService) private notificationService: DMNotificationService,
        @Inject(DmNotificationService) private notificationServiceV2: DmNotificationService,
        @Inject(StaffingService) private staffingService: StaffingService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(EngagementDetailService) private engagementDetailService: EngagementDetailService,
        @Inject(DmDisplayDateOrDashPipe) private dmDisplayDateOrDashPipe: DmDisplayDateOrDashPipe,
        @Inject(MyPortfolioService) private myPortfolioService: MyPortfolioService,
        @Inject(ProjectServiceFunctions) private projectServiceFunction: ProjectServiceFunctions,
        @Inject(AuditService) private auditService: AuditService,
        @Inject(FormBuilder) private fb: FormBuilder) {
        super(activeModal, dmLogger, Components.ManageEbsEditEngagementDetails);
    }

    public ngOnInit(): void {
        this.entityType = "Engagement";
        this.serviceResponseMessages = WBSResponseMessages.Engagement;
        this.logEvent = LogEventConstants.ManageEBSEngagementEdit;
        this.notificationEventName = NotificationEvent.EngagementUpdated;
        this.initialStartDate = this.selectedEngagement.startDate;
        this.initialEndDate = this.selectedEngagement.endDate;      

        this.initializeEditEngagementDetailsForm();
        this.sharedFunctionsService.focus(AccessibilityConstants.CloseUpdateButton, true);
        this.modalContent = {
            title: "Edit " + this.entityType + " Details"
        };
        this.configurationService.initialize().then(() => {
            this.notificationMessage = this.configurationService.getValue<any>("Notification");
            this.disableEBSStartDateEndDateUpdates = this.configurationService.getValue<boolean>("disableEBSStartDateEndDateUpdates");
            this.disableDateChangeForPubSecCodes = this.configurationService.getValue<object>("disableDateChangeForPubSecCodes");
            this.conflictResourceRequestStatus = this.configurationService.getValue<string[]>("conflictResourceStatus");
            this.isEngagementMinDateValidationEnabled = this.configurationService.isFeatureEnabled("enableEngagementMinDateValidation");
            if (this.isEngagementMinDateValidationEnabled) {
                this.minStartDate = this.selectedEngagement.signatureDate;
            }
            /* Disabling ability to edit start and end dates based on the engagement's pubsec country code. */
            if (Object.keys(this.disableDateChangeForPubSecCodes).indexOf(this.selectedEngagement.publicSectorCode) > -1) {
                this.pubSecCountry = this.disableDateChangeForPubSecCodes[this.selectedEngagement.publicSectorCode];
                this.isDisabledPubSecCode = true;
                this.disableEBSStartDateEndDateUpdates = true;
                this.isResourceRequestsLoading = false;
            }

            this.isUpdatingEngagement = false;

            this.showCascadeRadioButtons = false;
            this.showConflictingResources = false;
            this.noOfConflictResources = 0;

            const demandSourceObjects: IDemandSourceObject[] = [];
            if (this.selectedEngagement && this.selectedEngagement.projects) {
                for (const project of this.selectedEngagement.projects) {
                    const demandSourceObject: IDemandSourceObject = {
                        projectId: project.id,
                        compassOnePackageId: project.compassOnePackageId
                    };
                    demandSourceObjects.push(demandSourceObject);
                }
            }

            if (!this.disableEBSStartDateEndDateUpdates && !this.isDisabledPubSecCode) {
                this.disableEBSStartDateEndDateUpdates = true;
                this.isResourceRequestsLoading = true;

                this.staffingService.getGRMRequestsProjectIdV2(demandSourceObjects).then((resRequests) => {
                    this.resourceRequests = resRequests.ProjectRequests;
                    this.getResourceConflictStartEndDates(resRequests).then(() => {
                        /* We call this API but don't actually do anything with the response */
                        this.isResourceRequestsLoading = false;
                        this.disableEBSStartDateEndDateUpdates = false;
                    });
                }).catch((error) => {
                    const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                    this.logError(SourceConstants.Method.NgOnInit, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isResourceRequestsLoading = false;
                });
            }
        });
    }


    /**
     * Creates the message that the engagement start date must be earlier than or the same as the project
     * start date. Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getStartDateMustBeEarlierThanProjectMessage(): string {
        return DmError.EbsStructure.EngagementStartDateMustBeEarlierOrSameAsProject + ` (${this.dmDisplayDateOrDashPipe.transform(this.selectedEngagement.childEntitiesMinStartDate)}).` +
            DmError.EbsStructure.EitherApplyOrManuallyChangeDateAtProjectlevel;
    }

    /**
     * Creates the message that the engagement end date cannot be earlier than or the same as the Project
     * end date. Sets the message in a single place so it can be edited once and reflected in all locations.
     */
    public getEndDateCannotBeEarlierThanProjectMessage(): string {
        return DmError.EbsStructure.EngagementEndDateCannotBeEarlierOrSameAsProject + ` (${this.dmDisplayDateOrDashPipe.transform(this.selectedEngagement.childEntitiesMaxEndDate)}).` +
            DmError.EbsStructure.EitherApplyOrManuallyChangeDateAtProjectlevel;
    }

    public onUpdateLevelChange(updatedLevel: EnumUpdateLevel): void {
        this.updateLevel.setValue(updatedLevel);
        this.hideErrorMessages();
    }

    /**
     * Is the save button on the UI disabled? Returns true if disabled, false otherwise.
     * Info is based on the validity of the input across all fields and if the fields have been edited at all.
     * @param formInvalid
     */
    public saveButtonDisabled(formInvalid: boolean): boolean {
        this.isNamePristine = this.engagementName.value === this.selectedEngagement.name;
        this.isDescriptionPristine = this.engagementDescription.value === this.selectedEngagement.description;
        this.isStartDatePristine = moment(this.selectedEngagement.startDate).isSame(this.engagementStartDate.value);
        this.isEndDatePristine = moment(this.selectedEngagement.endDate).isSame(this.engagementEndDate.value);

        const dates: boolean = this.isStartDateRequired || this.isEndDateRequired || this.showEndDateGreaterThanStartDateMessage;
        /* If we are showing the option to cascade, then it does not matter if the dates are before/after the child start/end dates.
        But if we are not showing the option to cascade, then these dates should block the ability to save. */

        const aqrDateValidations: boolean = this.showActualsStartDateConflictMessage || this.showConflictingResources || this.showActualsEndDateConflictMessage;

        const isFormPristineNew: boolean = this.isNamePristine && this.isDescriptionPristine && this.isStartDatePristine && this.isEndDatePristine;

        return formInvalid || dates || isFormPristineNew || aqrDateValidations || this.isUpdateActive || (this.engagementName.value && this.engagementName.value.length < 3 && !this.isNamePristine);
    }

    /**
     * Updates the Engagement Details by calling the API, sending any notifications, etc.
     */
    public updateEngagementDetails(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.UpdateEngagementDetails, LogEventConstants.ManageEBSEngagementEditSubmitClicked);

        this.loadingText = "Updating Engagement Details";
        this.isUpdateActive = true;
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();
        let editEngagementHasDate: boolean = false;

        const editEngagementDataRequest: IWbsEditEngagementDetailsV2 = {};
        if (this.engagementName.value !== this.selectedEngagement.name) {
            editEngagementDataRequest.name = this.engagementName.value;
        }
        if (this.engagementDescription.value !== this.selectedEngagement.description) {
            editEngagementDataRequest.description = this.engagementDescription.value;
        }
        if (!moment(this.engagementStartDate.value as Date).isSame(this.selectedEngagement.startDate as Date, "day")) {
            editEngagementHasDate = true;
            editEngagementDataRequest.startDate = moment(this.engagementStartDate.value as Date).format(DATE_FORMAT);
            editEngagementDataRequest.shouldCascadeUpdate = this.updateLevel.value !== EnumUpdateLevel.engagementLevel;
        }
        if (!moment(this.engagementEndDate.value as Date).isSame(this.selectedEngagement.endDate as Date, "day")) {
            editEngagementHasDate = true;
            editEngagementDataRequest.endDate = moment(this.engagementEndDate.value as Date).format(DATE_FORMAT);
            editEngagementDataRequest.shouldCascadeUpdate = this.updateLevel.value !== EnumUpdateLevel.engagementLevel;
        }

        // Sends a request if any field has been modified
        // Run disconnected date change process for customer engagements where date is updated
        if (editEngagementHasDate) {
            this.isUpdatingEngagement = true;
            const dateValidationRequest: IDateValidationRequest = {
                startDate: editEngagementDataRequest.startDate ? editEngagementDataRequest.startDate : moment(this.selectedEngagement.startDate).format(DATE_FORMAT),
                endDate: editEngagementDataRequest.endDate ? editEngagementDataRequest.endDate : moment(this.selectedEngagement.endDate).format(DATE_FORMAT),
                cascadeDown: editEngagementDataRequest.shouldCascadeUpdate
            };

            this.wbsService.validateWbsDates(dateValidationRequest, this.selectedEngagement.id).then((response: any) => {
                if (response.status === 200) {
                    const orchestrationId: string = uuid();
                    editEngagementDataRequest.startDate = dateValidationRequest.startDate;
                    editEngagementDataRequest.endDate = dateValidationRequest.endDate;

                    this.notificationServiceV2.createNotificationSubscriptionEntry(NotificationType.DateChange, loggedInUserData.BusinessPartnerId, this.selectedEngagement.id, orchestrationId).then(() => {
                        this.projectServiceFunction.orchestrateDateChange(editEngagementDataRequest, this.selectedEngagement.id, this.selectedEngagement.name, orchestrationId, WbsLevel.Engagement, loggedInUserData.alias).then(() => {
                            this.fxpMessageService.addMessage("Date Change has been successfully initiated. Please check the notification bar below for completion status.", this.FXP_CONSTANTS.messageType.success, false);
                            // Displays the new notification in the notification bar
                            this.notificationServiceV2.addNotificationToStore(loggedInUserData.alias, loggedInUserData.BusinessPartnerId, this.selectedEngagement.id, orchestrationId, NotificationType.DateChange);
                            this.isUpdatingEngagement = false;
                            this.closeModal();
                        });
                    });
                }
            }).catch((error) => {
                let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                failureMessage = failureMessage.replace("#", this.selectedEngagement.name);
                this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.UpdateEngagementDetails, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.isUpdatingEngagement = false;
                this.isUpdateActive = false;
            });
        } else if (editEngagementDataRequest && editEngagementDataRequest.name || editEngagementDataRequest.description) {
            this.isUpdatingEngagement = true;
            this.wbsService.updateEngagementDetailsV2(editEngagementDataRequest, this.selectedEngagement.id)
                .then((response: any) => {
                    this.engagementDetailService.invalidateStoreOnRefresh(this.selectedEngagement.id);
                    this.myPortfolioService.refreshMyPortfolioEngagementList();

                    const changedProperties: IChangedProperties[] = [];
                    const newName = editEngagementDataRequest.name ? editEngagementDataRequest.name : this.selectedEngagement.name;
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("Engagement Name", this.selectedEngagement.name, newName));
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("Delivery State", this.selectedEngagement.statusCode, this.selectedEngagement.statusCode));
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("Start Date", moment(this.selectedEngagement.startDate).format(DATE_FORMAT), moment(this.selectedEngagement.startDate).format(DATE_FORMAT)));
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("End Date", moment(this.selectedEngagement.endDate).format(DATE_FORMAT), moment(this.selectedEngagement.endDate).format(DATE_FORMAT)));
                    const oldDescription = this.selectedEngagement.description ? this.selectedEngagement.description : "No previous description provided";
                    const newDescription = editEngagementDataRequest.description ? editEngagementDataRequest.description : this.selectedEngagement.description;
                    this.wbsService.pushToArrayIfTrue(changedProperties, this.wbsService.createChangedPropertyObject("Engagement Description", oldDescription, newDescription));

                    if (changedProperties.length > 0 && !this.sharedFunctionsService.disableEmailAlertsNotificationsUpdateEBS(editEngagementDataRequest)) {
                        this.createLogAndSendNotification(changedProperties);
                    }
                    if (response && response.status && response.status === 206) {
                        let partialSucessMessage: string = this.serviceResponseMessages.OnSavePartialSuccess;
                        partialSucessMessage = partialSucessMessage.replace("#", newName);
                        this.fxpMessageService.addMessage(partialSucessMessage, this.FXP_CONSTANTS.messageType.warning);
                    } else {
                        let successMessage: string = this.serviceResponseMessages.OnSaveSuccess;
                        successMessage = successMessage.replace("#", newName);
                        this.fxpMessageService.addMessage(successMessage, this.FXP_CONSTANTS.messageType.success);
                    }
                    this.logEngagementUpdateToAudit();
                    this.closeModal();
                })
                .catch((error) => {
                    let failureMessage: string = this.serviceResponseMessages.OnSaveFailure;
                    failureMessage = failureMessage.replace("#", this.selectedEngagement.name);
                    this.fxpMessageService.addMessage(failureMessage, this.FXP_CONSTANTS.messageType.error);
                    this.logError(SourceConstants.Method.UpdateEngagementDetails, error, failureMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.isUpdatingEngagement = false;
                    this.isUpdateActive = false;
                });
        }
    }  

    /**
     * Toggles if the view resource is enabled
     */
    public toggleResources(): void {
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.ToggleResources, this.viewResourceEnable ? LogEventConstants.EBSEditToggleResourceCollapse : LogEventConstants.EBSEditToggleResourceExpand);
        this.viewResourceEnable = !this.viewResourceEnable;
    }

    /**
     * Capturing emitted start date value
     * @param startDate
     * @memberof WbsEngagementEditModalComponent
     */
    public onStartDateChange(startDate: Date): void {
        this.engagementStartDate.setValue(startDate);
        this.showConflictingResources = false;
        this.isEngagementStartDateValid();
    }

    /**
     * Checks validity of the start date by checking with GRM API
     */
    public isEngagementStartDateValid(): boolean {
        if (this.maxStartDate && (moment(this.engagementStartDate.value) > moment(this.maxStartDate))) {
            return false;
        }
        this.updateLevel.setValue(EnumUpdateLevel.engagementLevel);
        this.isStartDateRequired = false;
        this.showCascadeRadioButtons = false;
        this.disableEngagementOnlyOption = false;
        if (!this.engagementStartDate.value) {
            this.isStartDateRequired = true;
            return false;
        }
        this.showEndDateGreaterThanStartDateMessage = false;
        if (this.engagementStartDate.value && this.engagementEndDate.value && moment(this.engagementEndDate.value).isBefore(this.engagementStartDate.value)) {
            this.showEndDateGreaterThanStartDateMessage = true;
            return false;
        }

        if (!this.validateLowerLevelConflicts(this.engagementStartDate.value, this.engagementEndDate.value)) {
            return false;
        }

        if (!this.areDatesValid(this.engagementStartDate.value, this.engagementEndDate.value)) {
            return false;
        }
        let returnValue: boolean = true;
        this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(true, this.engagementStartDate.value, this.resourceRequests);
        if (this.noOfConflictResources > 0) {
            this.showConflictingResources = true;
            returnValue = false;
        } else {
            if (this.engagementEndDate.value) {
                this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(false, this.engagementEndDate.value, this.resourceRequests);
                if (this.noOfConflictResources > 0) {
                    this.showConflictingResources = true;
                    returnValue = false;
                }
            }
        }

        this.showActualsStartDateConflictMessage = false;

        if (this.selectedEngagement.minDate && moment(this.engagementStartDate.value).isAfter(this.selectedEngagement.minDate)) {
            this.showActualsStartDateConflictMessage = true;
            returnValue = false;
        }
        if (!returnValue) {
            return false;
        }
        this.showCascadeRadioButtons = this.haveDatesChanged(this.selectedEngagement.startDate, this.engagementStartDate.value) || this.haveDatesChanged(this.selectedEngagement.endDate, this.engagementEndDate.value);
        return true;
    }

    /**
     * Capturing emitted end date value
     *
     * @param {Date} endDate
     * @memberof WbsEngagementEditModalComponent
     */
    public onEndDateChange(endDate: Date): void {
        this.engagementEndDate.setValue(endDate);
        this.showConflictingResources = false;
        this.isEngagementEndDateValid();
    }

    /**
     * Checks validity of the end date by checking with GRM API
     */
    public isEngagementEndDateValid(): boolean {
        if (this.minEndDate && (moment(this.engagementEndDate.value) < moment(this.minEndDate))) {
            return false;
        }
        this.updateLevel.setValue(EnumUpdateLevel.engagementLevel);
        this.isEndDateRequired = false;
        this.showCascadeRadioButtons = false;
        this.disableEngagementOnlyOption = false;
        if (!this.engagementEndDate.value) {
            this.isEndDateRequired = true;
            return false;
        }

        this.showEndDateGreaterThanStartDateMessage = false;
        if (this.engagementStartDate.value && this.engagementEndDate.value && moment(this.engagementEndDate.value).isBefore(this.engagementStartDate.value)) {
            this.showEndDateGreaterThanStartDateMessage = true;
            return false;
        }
        if (!this.validateLowerLevelConflicts(this.engagementStartDate.value, this.engagementEndDate.value)) {
            return false;
        }

        if (!this.areDatesValid(this.engagementStartDate.value, this.engagementEndDate.value)) {
            return false;
        }
        let returnValue = true;

        this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(false, this.engagementEndDate.value, this.resourceRequests);
        if (this.noOfConflictResources > 0) {
            this.showConflictingResources = true;
            returnValue = false;
        } else {
            if (this.engagementStartDate.value) {
                this.noOfConflictResources = this.sharedFunctionsService.getCountOfResourceConflicts(true, this.engagementStartDate.value, this.resourceRequests);
                if (this.noOfConflictResources > 0) {
                    this.showConflictingResources = true;
                    returnValue = false;
                }
            }
        }

        this.showActualsEndDateConflictMessage = false;
        // TODO - Get enddate from staffing
        if (this.selectedEngagement.maxDate && moment(this.engagementEndDate.value).isBefore(this.selectedEngagement.maxDate)) {
            this.showActualsEndDateConflictMessage = true;
            returnValue = false;
        }
        if (!returnValue) {
            return false;
        }
        this.showCascadeRadioButtons = this.haveDatesChanged(this.selectedEngagement.startDate, this.engagementStartDate.value) || this.haveDatesChanged(this.selectedEngagement.endDate, this.engagementEndDate.value);
        return true;

    }

    /**
     * Hides the error messages related to updating different date levels.
     */
    public hideErrorMessages(): void {
        if (this.updateLevel.value === EnumUpdateLevel.allLowerLevels) {
            this.isBeforeChildProjectEndDate = false;
            this.isAfterChildProjectStartDate = false;
        }
    }

    /**
     * Logs the change events to the DMUX Application Insights telemetry and sends email notifications to the
     * relevant people.
     * @param engagementId
     * @param changedProperties
     */
    private createLogAndSendNotification(changedProperties: IChangedProperties[]): void {
        const propertyBag = {};
        const currentUser = this.fxpUserInfoService.getCurrentUser();
        propertyBag[LogEventConstants.ChangedValues] = this.wbsService.createLogStringFromChangedProperties(changedProperties);
        this.dmLogger.logEvent(SourceConstants.Component.ManageEBSPage, SourceConstants.Method.CreateLogAndSendNotification, this.logEvent, propertyBag);
        const notification = new NotificationModel();
        let esxpNotification = this.notificationMessage.EngagementNotification;
        const sendTo = this.sharedFunctionsService.getListofPjmV2(this.selectedEngagement).concat(currentUser);
        esxpNotification = esxpNotification.replace("#", this.engagementName.value);
        notification.eventName = this.notificationEventName;
        notification.engagementId = this.selectedEngagement.id;
        notification.engagementName = this.engagementName.value;
        notification.sendTo = this.sharedFunctionsService.getArrayWithoutDupes(sendTo);
        notification.modifiedBy = currentUser;
        notification.modifiedDate = new Date();
        notification.changedProperties = changedProperties;
        this.notificationService.sendNotification(notification, false, esxpNotification);
    }

    /**
     * Checks the validity of the given start and end dates in relation to each other and lower level conflicts.
     * Returns false if the dates are not valid, true if they otherwise are valid.
     * @param startDate
     * @param endDate
     */
    private areDatesValid(startDate: Date, endDate: Date): boolean {
        return !moment(this.engagementEndDate.value).isBefore(this.engagementStartDate.value)
            && this.validateLowerLevelConflicts(startDate, endDate);
    }

    /**
     * Compares the given start and end dates. Returns true if the dates have changed.
     *
     * @param originalDate
     * @param newDate
     */
    private haveDatesChanged(originalDate: Date, newDate: Date): boolean {
        return !moment(originalDate).isSame(newDate);
    }

    /**
     * Validates any lower level conflicts by comparing dates. Returns true if valid, false if invalid
     * @param startDate
     * @param endDate
     */
    private validateLowerLevelConflicts(startDate: Date, endDate: Date): boolean {
        let isValid = true;
        this.isAfterChildProjectStartDate = false;
        this.isBeforeChildProjectEndDate = false;
        this.isAfterChildServiceStartDate = false;
        if (this.updateLevel.value === EnumUpdateLevel.engagementLevel) {
            if (this.selectedEngagement.childEntitiesMinStartDate && moment(startDate).isAfter(this.selectedEngagement.childEntitiesMinStartDate)) {
                this.isAfterChildProjectStartDate = true;
                isValid = false;
            }
            if (this.selectedEngagement.childEntitiesMaxEndDate && moment(endDate).isBefore(this.selectedEngagement.childEntitiesMaxEndDate)) {
                this.isBeforeChildProjectEndDate = true;
                isValid = false;
            }
        }

        if (this.isBeforeChildProjectEndDate || this.isAfterChildProjectStartDate) {
            /* If the cascade options are available and the user has an error with their dates, continue to show the error,
            but set the cascade option to all levels and allow them to save the engagement. Disable engagement only option so
            the user cannot ignore the error. */
            this.isBeforeChildProjectEndDate = false;
            this.isAfterChildProjectStartDate = false;
            this.showCascadeRadioButtons = true;
            this.disableEngagementOnlyOption = true;
            this.updateLevel.setValue(EnumUpdateLevel.allLowerLevels);
            isValid = true;
        }
        return isValid;
    }

    /**
     * Initializes contact form data
     *
     * @private
     * @memberof CreateCSATContactModalComponent
     */
    private initializeEditEngagementDetailsForm(): void {
        this.editEngagementDetailsForm = this.fb.group({
            engagementName: [(this.selectedEngagement && this.selectedEngagement.name) ? this.selectedEngagement.name : "", Validators.required],
            engagementDescription: [(this.selectedEngagement && this.selectedEngagement.description) ? this.selectedEngagement.description : "", Validators.required],
            engagementStartDate: [this.initialStartDate, [Validators.required]],
            engagementEndDate: [this.initialEndDate, [Validators.required]],
            updateLevel: [EnumUpdateLevel.engagementLevel]
        });
    }

    /**
     * Gets the resource conflict start and end dates from staffing data.
     *
     * @private
     * @returns {Promise<IScheduledDates>}
     * @memberof WbsProjectEditModalInstanceComponent
     */
    private getResourceConflictStartEndDates(grmStaffingResponse: IResourceRequestResponse): Promise<IScheduledDates> {
        const scheduledStartDates = [];
        const scheduledEndDates = [];
        if (grmStaffingResponse && grmStaffingResponse.ProjectRequests && grmStaffingResponse.ProjectRequests.length > 0) {
            for (const projectRequest of grmStaffingResponse.ProjectRequests) {
                if (projectRequest.ResourceRequests && projectRequest.ResourceRequests.length > 0) {
                    const filteredResourceRequests = projectRequest.ResourceRequests.filter((resRequests) => this.conflictResourceRequestStatus.indexOf(resRequests.ResourceRequestStatusEnum) >= 0);
                    if (filteredResourceRequests.length > 0) {
                        this.noOfConflictResources = filteredResourceRequests.length;
                        for (const request of filteredResourceRequests) {
                            scheduledStartDates.push(moment(request.ScheduledStartDate));
                            scheduledEndDates.push(moment(request.ScheduledEndDate));
                        }
                    }
                }
            }
        }
        if (scheduledStartDates.length && scheduledEndDates.length) {
            const scheduledDates = {
                scheduledStartDate: moment.min(scheduledStartDates).toDate(),
                scheduledEndDate: moment.max(scheduledEndDates).toDate()
            };
            return Promise.resolve(scheduledDates);
        } else {
            return Promise.resolve(undefined);
        }
    }

    /**
     * Logs Engagementdetails Update to Audit    
     */
    private logEngagementUpdateToAudit(): void {
        const loggedInUserData = this.fxpUserInfoService.getCurrentUserData();        
        if (this.engagementName.value !== this.selectedEngagement.name) {            
            const logDetails: IWbsAuditPayload = {
                currentValue: this.engagementName.value,
                previousValue: this.selectedEngagement.name,
                eventType: WbsAuditType.EngagementNameChange,
                createdByAlias: loggedInUserData.alias
            };
            this.auditService.postWbsAuditItem(this.selectedEngagement.id, logDetails);
        }
        if (this.engagementDescription.value !== this.selectedEngagement.description) {            
            const logDetails: IWbsAuditPayload = {
                currentValue: this.engagementDescription.value,
                previousValue: this.selectedEngagement.description,
                eventType: WbsAuditType.EngagementDescriptionChange,
                createdByAlias: loggedInUserData.alias
            };
            this.auditService.postWbsAuditItem(this.selectedEngagement.id, logDetails);
        }
    }
}
