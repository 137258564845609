<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'344px'" [hasNonOverflowContent]="true"
    [isLoading]="isUpdatingProject || viewResourceEnable">
    <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true" *ngIf="isUpdatingProject">
    </dm-loading>
    <div *ngIf="!isUpdatingProject && !viewResourceEnable">
        <!-- Form section -->
        <form [formGroup]="editProjectDetailsForm">
            <div class="dm-form-entity" [ngClass]="{'dm-m-b-imp-20': (projectName?.dirty || projectName?.touched) && (!projectName.value.length || (projectName.value && projectName.value.length < 3))}">
                <label for="dm-form-entity__label">
                    Name
                    <span class="required-mark" aria-hidden="true">*</span>
                </label>
                <input class="font-caption-alt dm-input" type="text" formControlName="projectName" maxlength="40"
                    [ngClass]="{'error-border': (projectName?.dirty || projectName?.touched) && projectName.invalid}"
                    id="projectName" name="projectName" aria-label="Project name" placeholder="Enter Name">
                <span class="font-caption pull-right dm-m-t-4" aria-hidden="true">
                    {{40 - projectName.value.length}}{{editProjectErrorMessages.EditEbsStructure.NameMaxLength}}
                </span>
                <span role="alert" class="font-caption-alt inp-validation pull-left" aria-atomic="true"
                    aria-live="polite"
                    *ngIf="(projectName?.dirty || projectName?.touched) && !projectName.value.length">
                    {{editProjectErrorMessages.ProjectNameIsRequired}}
                </span>
                <span role="alert" class="font-caption-alt inp-validation pull-left" aria-live="polite"
                    *ngIf="projectName.value && projectName.value.length < 3">
                    {{editProjectErrorMessages.EditEbsStructure.NameMinimumCharacterLength}}
                </span>
            </div>
            <div class="dm-form-entity">
                <label class="dm-form-entity__label" for="projectDescription">
                    Description
                    <span class="required-mark" aria-hidden="true">*</span>
                </label>
                <textarea name="projectDescription" placeholder="Enter Description" id="description"
                    [ngClass]="{'error-border' : projectDescription.touched && projectDescription.invalid}"
                    formControlName="projectDescription" aria-label="project Description" maxlength="255"
                    required></textarea>
                <span class="font-caption pull-right dm-m-t-4" aria-hidden="true">
                    {{255 - projectDescription.value.length}}{{editProjectErrorMessages.EditEbsStructure.DescriptionMaxLength}}
                </span>
                <span role="alert" class="font-caption-alt inp-validation pull-left" aria-atomic="true"
                    aria-live="polite"
                    *ngIf="(projectDescription?.dirty || projectDescription?.touched) && !projectDescription.value.length">
                    {{editProjectErrorMessages.DescriptionRequired}}
                </span>
            </div>

            <div class="col-sm-12 col-lg-12 dm-p-l-imp-0">
                <div class="col-sm-6 col-lg-6 dm-datepicker dm-p-l-imp-0">
                    <label class="dm-form-entity__label" for="startDate">
                        Start Date
                    </label>
                    <dm-datepicker id="startDate" name="projectStartDate" formControlName="projectStartDate"
                        [disabled]="disableEBSStartDateEndDateUpdates" [modelDate]="projectStartDate.value"
                        [minDate]="minDate" [maxDate]="maxStartDate ? maxStartDate : projectEndDate.value"
                        ariaLabelButton="Start date" [placeholder]="'Select a date...'" ariaLabelCalendar="Start date"
                        (onDateChange)="onStartDateChange($event)" previosFousingElementId="description"
                        [showErrorBorder]="isStartDateRequired || isBeforeEngagementStartDate || isAfterChildServiceStartDate"
                        [isDatePickerV2]="true">
                    </dm-datepicker>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isStartDateRequired">
                        {{editProjectErrorMessages.StartDateRequired}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isBeforeEngagementStartDate">
                        {{getStartDateMustBeAfterEngStartMessage()}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isAfterChildServiceStartDate">
                        {{getStartDateMustBeEarlierThanServiceMessage()}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="showActualsStartDateConflictMessage">
                        {{editProjectErrorMessages.ProjectStartDateMustBeEalierOrSameAsActualDate}}
                        ({{selectedProject.minDate | dmDisplayDateOrDashPipe}}).
                    </span>
                </div>
                <div class="dm-form-entity col-sm-6 col-lg-6 dm-datepicker dm-p-imp-0">
                    <label class="dm-form-entity__label" for="newEndDate">
                        End Date
                    </label>
                    <dm-datepicker id="endDate" name="projectEndDate"
                        [minDate]="minEndDate ? minEndDate : projectStartDate.value" [maxDate]="engagementEndDate"
                        [modelDate]="projectEndDate.value" [disabled]="disableEBSStartDateEndDateUpdates"
                        formControlName="projectEndDate" [placeholder]="'Select a date...'" ariaLabelButton="End date"
                        ariaLabelCalendar="End date" (onDateChange)="onEndDateChange($event)"
                        previosFousingElementId="calenderBtn_Start date"
                        [showErrorBorder]="isEndDateRequired || isDateCompare || isAfterEngagementEndDate || isBeforeChildServiceEndDate"
                        [isDatePickerV2]="true">
                    </dm-datepicker>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isEndDateRequired">
                        {{editProjectErrorMessages.EndDateIsRequired}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isDateCompare">
                        {{editProjectErrorMessages.EndDateCannotBeEarlierThanStartDate}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isAfterEngagementEndDate">
                        {{getEndDateMustBeBeforeEngEndMessage()}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="isBeforeChildServiceEndDate">
                        {{getEndDateCannotBeBeforeServiceEndMessage()}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="showActualsEndDateConflictMessage">
                        {{getEndDateMustBeAfterActualsMessage()}}
                    </span>
                    <span role="alert" class="font-caption-alt inp-validation" aria-atomic="true" aria-live="polite"
                        *ngIf="showIODateValidationMessage">
                        {{editProjectErrorMessages.ProjectEndDateCannotBeLaterThanIOEndDate}} ({{selectedProject.ioDate |
                        dmDisplayDateOrDashPipe}}).
                    </span>
                </div>
                <div class="dm-p-x-5 spinner" *ngIf="isResourceRequestsLoading">
                    <dm-refresh *ngIf="isResourceRequestsLoading" [showSpinning]="isResourceRequestsLoading"
                        [enableRefresh]="false" [loadingText]="'Loading resource request details'">
                    </dm-refresh>
                </div>
            </div>
            <section class="col-md-12 col-sm-12 radio__section" *ngIf="showCascadeRadioButtonsBlock">
                <section class="col-md-12 col-sm-12 dm-p-imp-0 clearfix modal__input--radio">
                    <div class="dm-p-y-10">
                        Apply date changes to
                    </div>
                    <section class="dm-p-y-10">
                        <section class="col-md-6 col-sm-6 dm-p-imp-0">
                            <label for="checkProject" focus="true" class="dm-inline-flex-align-center">
                                <span class="input-radio input-radio--focus" *ngIf="!disableProjectOnlyOption">
                                    <input id="checkProject" type="radio" name="updateLevel"
                                        formControlName="updateLevel" (change)="onUpdateLevelChange(updateLevel.value)"
                                        [value]="EnumUpdateLevel.projectLevel">
                                    <span class="slider round" aria-hidden="true"></span>
                                </span>
                                <input id="checkProject" type="radio" name="updateLevel" *ngIf="disableProjectOnlyOption"
                                        formControlName="updateLevel" (change)="onUpdateLevelChange(updateLevel.value)"
                                        disabled
                                        [value]="EnumUpdateLevel.projectLevel">
                                <span class="radiobtn-label font-caption-alt dm-p-l-10">
                                    Project Only
                                </span>
                            </label>
                        </section>
                        <section class="col-md-6 col-sm-6 dm-p-imp-0">
                            <label for="checkAll" focus="true" class="dm-inline-flex-align-center">
                                <span class="input-radio input-radio--focus">
                                    <input id="checkAll" type="radio" name="updateLevel" formControlName="updateLevel"
                                        (change)="onUpdateLevelChange(updateLevel.value)"
                                        [value]="EnumUpdateLevel.allLowerLevels">
                                    <span class="slider round" aria-hidden="true"></span>
                                </span>
                                <span class="radiobtn-label font-caption-alt dm-p-l-10">
                                    Project,Services and Tasks
                                </span>
                            </label>
                        </section>
                        <div class="clearfix"></div>
                    </section>
                </section>
            </section>
            <section class="font-label dm-p-b-12 dm-p-t-4" role="alert" aria-atomic="true" aria-live="polite"
                *ngIf="showConflictingResources">
                <span class="font-caption-alt inp-validation">
                    {{getCannotChangeDateResourceConflictMessage()}}.
                </span>
                <button class="dm-link" type="button" (click)="toggleResources()" aria-label="View resource dialogue.">
                    View resources
                </button>
            </section>
        </form>
    </div>
    <div infoBar
        *ngIf="!isUpdatingProject && !viewResourceEnable && (!isNamePristine || !isDescriptionPristine || !isStartDatePristine || !isEndDatePristine)">
        <div class="dm-modal-v2-change-information-section" *ngIf="!isNamePristine" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Project name has been changed from '{{selectedProject.name}}'</span>
        </div>
        <div class="dm-modal-v2-change-information-section" *ngIf="!isDescriptionPristine" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Project description has been changed from '{{selectedProject.description}}'</span>
        </div>
        <div class="dm-modal-v2-change-information-section" *ngIf="!isStartDatePristine" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Project start date has been changed from
                '{{selectedProject.startDate | dmDisplayDateOrDashPipe}}'</span>
        </div>
        <div class="dm-modal-v2-change-information-section" *ngIf="!isEndDatePristine" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Project end date has been changed from
                '{{selectedProject.endDate | dmDisplayDateOrDashPipe}}'</span>
        </div>
    </div>
    <section class="resource-details-list" *ngIf="viewResourceEnable">
        <div class="popUpModalDialog editProjectForm">
            <section class="modal-header modal-noborder">
                <a class="dm-link font-caption-alt cursor-pointer" type="button" (click)="toggleResources()">
                    <i class="icon icon-Back"></i>
                    <span class="dm-p-r-10"> Return to Edit Project</span>
                </a>
            </section>
            <div class="modal-body resources-section">
                <dm-wbs-resource-requests [startDate]="projectStartDate.value" [endDate]="projectEndDate.value"
                    [wbsId]="selectedProject.id">
                </dm-wbs-resource-requests>
            </div>
        </div>
    </section>
    <div additionalFooterButtons *ngIf="!viewResourceEnable">
        <button type="button" id="create" class="fxp-btn fxp-btn--primary dm-btn" *ngIf="!isUpdatingProject"
            [disabled]="saveButtonDisabled(!editProjectDetailsForm.valid)" (click)="updateProjectDetails()">
            Submit
        </button>
    </div>
</dm-modal-v2>