<dm-modal-v2 class="unitTransactionsModal" [modalHeaderContent]="modalContent" [modalMinHeight]="'430px'"
  [hasNonOverflowContent]="true" [showFooter]="false">
  <div class="unitTransactionsModal__loaderContainer">
    <dm-loading loader [loadingText]="'transaction details'" [showLoading]="true" class="dm-p-10"
      *ngIf="isLoadingUnitTransactionDetails">
    </dm-loading>
    <dm-no-data *ngIf="!isLoadingUnitTransactionDetails && unitTransactionDetails?.length <=0" class="dm-p-10"
      [noDataText]="noTransactionsText" [showImage]="true">
    </dm-no-data>
  </div>
  <div *ngIf="!isLoadingUnitTransactionDetails && unitTransactionDetails?.length > 0">
    <div class="unitTransactionsModal__infoDetails">
      <div class="display-grid pull-left">
        <span>{{unitActivityTypeCode}}</span>
        <span>{{unitActivityTypeName}}</span>
      </div>
    </div>
    <caption id="unitTransactionTable" class="sr-only">Unit Transactions</caption>
    <div role="grid" class="dm-unit-transactions-grid" aria-labelledby="unitTransactionTable">
      <div role="rowgroup" class="dm-grid-view-table dm-unit-transactions-table">
        <div role="row" class="dm-grid-view-table__row">
          <div role="gridcell" class="dm-grid-view-table__headerCell">Posted Date
          </div>
          <div role="gridcell" class="dm-grid-view-table__headerCell">Incurred Date
          </div>
          <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right">Actual <br /> Quantity</div>
          <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right">Unit Cost Rate <br />
            <span class="unitLabel">({{currency}})/ ea</span>
          </div>
          <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right">Total Delivered Cost<br />
            <span class="unitLabel">({{currency}})</span>
          </div>
          <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right">Invoiced
            <br />
            <div class="display-inline-block">
              Quantity<dm-tooltip [dmPlacement]="'bottom-right'"
                [ngbTooltipContent]="'Invoiced Quantity is applicable for only T&M projects'"
                [dmIcon]="'tile__icon--info icon icon-info'"
                ariaLabelToolTip="Invoiced Quantity is applicable for only T&M projects"></dm-tooltip>
                <span class="sr-only">press tab to navigate to tooltip button</span>
            </div>
          </div>
          <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right">Bill Rate <br />
            <div class="display-inline-block">
              <span class="unitLabel">({{currency}}/ea)</span>
              <dm-tooltip [dmPlacement]="'bottom-right'"
                [ngbTooltipContent]="'Bill rate is applicable for only T&M projects'"
                [dmIcon]="'tile__icon--info icon icon-info'"
                ariaLabelToolTip="Bill rate is applicable for only T&M projects"></dm-tooltip>
                <span class="sr-only">press tab to navigate to tooltip button</span>
            </div>
          </div>
          <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right">Invoiced Amount <br />
            <div class="display-inline-block">
              <span class="unitLabel">({{currency}})</span>
              <dm-tooltip [dmPlacement]="'bottom-right'"
                [ngbTooltipContent]="'Invoiced Amount is applicable for only T&M projects'"
                [dmIcon]="'tile__icon--info icon icon-info'"
                ariaLabelToolTip="Invoiced Amount is applicable for only T&M projects"></dm-tooltip>
                <span class="sr-only">press tab to navigate to tooltip button</span>
            </div>
          </div>
          <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right filter--cell">Billablity
            <br />
            Status
            <div class="dropdown" id="filterBillableStatusSection">
              <span type="text" id="filterBillableStatus" data-toggle="dropdown" aria-haspopup="true"
                class="dm-p-5 filter-billable-status" aria-expanded="false" tabindex="0"
                (keydown.shift.tab)="closeDropdownOnTab('filterBillableStatusSection')"
                (keydown.ArrowDown)="moveFocus('filterBillableStatus-All')">
                <i class="icon icon-filter" title="filter billability status" role="button" aria-haspopup="true" aria-label="filter billability status"></i>
              </span>
              <span class="sr-only">press tab to navigate to filter icon</span>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="filterBillableStatus">
                <div class="display-grid">
                  <span class="dropdown-item" id="filterBillableStatus-All" (click)="filterBillableStatus('all')"
                    (keyup.enter)="filterBillableStatus('all'); closeDropdownOnTab('filterBillableStatusSection')"
                    tabindex="0" (keydown.ArrowDown)="moveFocus('filterBillableStatus-Billable')">All</span>
                  <span class="dropdown-item" id="filterBillableStatus-Billable"
                    (click)="filterBillableStatus('billable')"
                    (keyup.enter)="filterBillableStatus('billable'); closeDropdownOnTab('filterBillableStatusSection')"
                    tabindex="0" (keydown.ArrowUp)="moveFocus('filterBillableStatus-All')"
                    (keydown.ArrowDown)="moveFocus('filterBillableStatus-NonBillable')">Billable</span>
                  <span class="dropdown-item" id="filterBillableStatus-NonBillable"
                    (click)="filterBillableStatus('nonBillable')"
                    (keyup.enter)="filterBillableStatus('nonBillable'); closeDropdownOnTab('filterBillableStatusSection')"
                    tabindex="0" (keydown.ArrowUp)="moveFocus('filterBillableStatus-Billable')"
                    (keydown.Tab)="closeDropdownOnTab('filterBillableStatusSection')">Non Billable</span>
                </div>
              </div>
            </div>
          </div>
          <div role="gridcell" class="dm-grid-view-table__headerCell text-align--right filter--cell">Invoiced <br />
            Status
            <div class="dropdown" id="filterInvoicedStatusSection">
              <span type="text" id="filterInvoicedStatus" data-toggle="dropdown" aria-haspopup="true"
                class="dm-p-5 filter-invoiced-status" aria-expanded="false" tabindex="0"
                (keydown.shift.tab)="closeDropdownOnTab('filterInvoicedStatusSection')"
                (keydown.ArrowDown)="moveFocus('filterInvoicedStatus-All')">
                <i class="icon icon-filter" title="filter invoiced status" role="button" aria-haspopup="true" aria-label="filter invoiced status"></i>
              </span>
              <span class="sr-only">press tab to navigate to filter icon</span>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="filterInvoicedStatus">
                <div class="display-grid">
                  <span class="dropdown-item" id="filterInvoicedStatus-All" (click)="filterInvoicedStatus('all')"
                    (keyup.enter)="filterInvoicedStatus('all'); closeDropdownOnTab('filterInvoicedStatusSection')"
                    tabindex="0" (keydown.ArrowDown)="moveFocus('filterInvoicedStatus-Invoiced')">All</span>
                  <span class="dropdown-item" id="filterInvoicedStatus-Invoiced"
                    (click)="filterInvoicedStatus('invoiced')"
                    (keyup.enter)="filterInvoicedStatus('invoiced'); closeDropdownOnTab('filterInvoicedStatusSection')"
                    tabindex="0" (keydown.ArrowUp)="moveFocus('filterInvoicedStatus-All')"
                    (keydown.ArrowDown)="moveFocus('filterInvoicedStatus-NotInvoiced')">Invoiced</span>
                  <span class="dropdown-item" id="filterInvoicedStatus-NotInvoiced"
                    (click)="filterInvoicedStatus('notInvoiced')"
                    (keyup.enter)="filterInvoicedStatus('notInvoiced'); closeDropdownOnTab('filterInvoicedStatusSection')"
                    tabindex="0" (keydown.ArrowUp)="moveFocus('filterInvoicedStatus-Invoiced')"
                    (keydown.Tab)="closeDropdownOnTab('filterInvoicedStatusSection'); moveFocus('unitTransactionDetailsToggle-0')">Not
                    Invoiced</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let item of unitTransactionDetails; let index = index">
          <div *ngIf="unitTransactionDetails && unitTransactionDetails?.length">
            <div role="row" class="dm-grid-view-table__row dm-unit-transactions-table__monthRow">              
              <div role="gridcell" class="dm-grid-view-table__bodyCell">
                <span class="sr-only">Posted Date</span>
                <span [id]="'unitTransactionDetailsToggle-' + index" tabindex="0" role="button"
                  class="dm-p-x-10 dm-grid-view-table__month-button set-focus" (click)="expandCollapse(item)"
                  (keyup.enter)="expandCollapse(item)" (keyup.Space)="expandCollapse(item)"
                  [title]="item.isExpanded ? 'Collapse expanded Month' : 'Expand collapsed Month'">
                  <span class="icon"
                    [ngClass]="{'icon-ChevronUp': item.isExpanded, 'icon-ChevronDown': !item.isExpanded}"
                    [attr.aria-expanded]="item.isExpanded ?true:false"></span>
                </span>
              <span>{{item?.monthYear}}</span>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell">
                <span class="sr-only">Incurred Date {{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                <span class="sr-only">Actual Quantity</span>
                <strong>{{item?.unitTransactions | sum: 'actualQuantity' | number : '1.2-2'}}</strong>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                <span class="sr-only">Unit cost Rate {{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                <span class="sr-only">Total Delivered Cost</span>
                <strong>{{item?.unitTransactions | sum: 'costAmount' | dmDisplayCurrencyOrDash: currency}}</strong>
                <span *ngIf="!item?.unitTransactions" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                <span class="sr-only">Invoiced Quantity</span>
                <strong>{{item?.unitTransactions | sum: 'invoicedQuantity' | number : '1.2-2'}}</strong>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                <span class="sr-only">Bill Rate {{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                <span class="sr-only">Invoiced Amount</span>
                <strong>{{item?.unitTransactions | sum: 'actualRevenue' | dmDisplayCurrencyOrDash: currency}}</strong>
                <span *ngIf="!item?.unitTransactions" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                <span class="sr-only">Billablity status {{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </div>
              <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                <span class="sr-only">Invoiced Status {{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </div>
            </div>
            <ng-container *ngIf="item?.isExpanded">
              <div role="row" class="dm-grid-view-table__row" *ngFor="let unitDetail of item?.unitTransactions">
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                  <span class="sr-only">Posted Date</span>
                  {{unitDetail?.postedDate | date:'dd-MMM-yyyy'}}
                  <span *ngIf="unitDetail?.postedDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell">
                  <span class="sr-only">Incurred Date</span>
                  {{unitDetail?.incurredDate | date:'dd-MMM-yyyy'}}
                  <span *ngIf="unitDetail?.incurredDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Actual Quantity</span>
                  {{unitDetail?.actualQuantity | number : '1.2-2'}}
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Unit Cost Rate</span>
                  {{unitDetail?.costRate | dmDisplayCurrencyOrDash: currency}}
                  <span *ngIf="!unitDetail?.costRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Total Delivered Cost</span>
                  {{+unitDetail?.costAmount | dmDisplayCurrencyOrDash: currency}}
                  <span *ngIf="!unitDetail?.costAmount" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Invoiced Quantity</span>
                  {{unitDetail?.invoicedQuantity | number : '1.2-2'}}
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Bill Rate</span>
                  {{unitDetail?.billRate | dmDisplayCurrencyOrDash: currency}}
                  <span *ngIf="!unitDetail?.billRate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right">
                  <span class="sr-only">Invoiced Amount</span>
                  {{+unitDetail?.actualRevenue | dmDisplayCurrencyOrDash: currency}}
                  <span *ngIf="!unitDetail?.actualRevenue" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right dm-m-r-20">
                  <span class="sr-only">Billablity Status</span>
                  <span *ngIf="!unitDetail?.isBillable" class="i-circle non-billable" [title]="'Non Billable'">NB</span>
                  <span *ngIf="unitDetail?.isBillable" class="i-circle billable" [title]="'Billable'">B</span>
                </div>
                <div role="gridcell" class="dm-grid-view-table__bodyCell text-align--right dm-m-r-20">
                  <span class="sr-only">Invoiced status</span>
                  <span *ngIf="unitDetail?.isInvoiced" class="icon icon-check dm-success icon-rounded"
                    [title]="'Invoiced'"></span>
                  <span *ngIf="!unitDetail?.isInvoiced" class="icon icon-clock icon-rounded not-invoiced"
                    [title]="'Not Invoiced'"></span>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</dm-modal-v2>